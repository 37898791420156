import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Seo } from '../components/utils'
import { TextContainer } from '../components/layout/TextContainer'

export default function ImprintPage() {
  const { imprintData } = useStaticQuery(graphql`
    {
      imprintData: sanityImprint {
        title
        _rawBody
      }
    }
  `)
  return (
    <>
      <Seo title="Über Uns" />
      <TextContainer
        heading={imprintData.title}
        body={imprintData._rawBody}
        isImprint={true}
      />
    </>
  )
}
